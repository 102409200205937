import {
  email_reg,
  mobile_or_email_reg,
  mobile_reg,
  pwd_reg,
  rucaptcha_reg,
  vercode_reg,
  nickname_reg,
} from "./all_reg_exp";

export const email_rule = { reg: email_reg, msg: "邮箱地址不合法" };
export const mobile_or_email_rule = {
  reg: mobile_or_email_reg,
  msg: "账户名不正确",
};
export const mobile_rule = { reg: mobile_reg, msg: "手机号不合法" };
export const pwd_rule = { reg: pwd_reg, msg: "密码为6-32位" };
export const rucaptcha_rule = { reg: rucaptcha_reg, msg: "验证码为5位" };
export const vercode_rule = { reg: vercode_reg, msg: "验证码为六位数字" };
export const nickname_rule = { reg: nickname_reg, msg: "昵称为4-16位" };

export const buildRules = (
  name,
  placeholder,
  rules,
  valid = true,
  value = "",
  msg = ""
) => ({
  name,
  value,
  msg,
  placeholder,
  valid,
  rules,
});
