import banner from "@shared/scripts/features/banner";

const home = () => {
  banner();

  const getRecommItems = () => {
    const $loading = $("#js-filter-loading");
    $loading.removeClass("is-hidden");
  };
  getRecommItems();

  $("#js-recommend-replace").on("click", () => {
    getRecommItems();
  });

  document.getElementById("js-daily-container").addEventListener("wheel", e => {
    const panel = e.currentTarget;
    if (panel.scrollHeight <= panel.clientHeight) return;

    const mt = panel.scrollTop;
    const { deltaY } = e;
    const isEnd = panel.scrollHeight - mt === panel.clientHeight;
    if (deltaY > 0 && isEnd) e.preventDefault();
  });
};

export default home;
