/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";
import Salert from "react-s-alert";

import {
  mobile_or_email_rule,
  pwd_rule,
  buildRules,
} from "@shared/scripts/utils/validation_rules";
import CustomizeInput from "../shared/CustomizeInput";
import FormWrapper from "../utils/FormWrapper";

class SigninForm extends React.Component {
  submit() {
    if (!this.props.isFormValidate()) return;

    const $form = $(this.refs.signinForm);
    $form.ajaxSubmit({
      url: $form.attr("action"),
      type: "POST",
      dataType: "json",
      success: res => {
        if (res.success) {
          Salert.success("登录成功");
          if (res.redirect) {
            window.location.href = res.redirect;
          } else {
            window.location.reload();
          }
        }
      },
    });
  }

  render() {
    const {
      data: { login_name, password },
      changeValue,
    } = this.props;

    return (
      <form
        ref="signinForm"
        action="/login"
        className="signin-form"
        onKeyDown={e => {
          if (e.which === 13) this.submit();
        }}
      >
        <input name="utf8" type="hidden" value="✓" />
        <input name="remember_me" type="hidden" value="0" />
        <CustomizeInput
          {...login_name}
          onChange={value => {
            changeValue(value, "login_name");
          }}
        />
        <CustomizeInput
          {...password}
          type="password"
          onChange={value => {
            changeValue(value, "password");
          }}
        />
        <a
          className="u-btn--black signin-form__submit"
          href="javascript:;"
          alt="登录"
          onClick={() => {
            this.submit();
          }}
        >
          登录
        </a>
        <p className="signin-form__tip text-right">
          <label className="signin-form__remember">
            <input
              className="signin-form__checkbox"
              type="checkbox"
              name="remember_me"
              value="1"
              placeholder="记住密码"
            />
            记住密码
          </label>
          <span>
            <a
              className="signin-form__signup signin-form__forget"
              href="/forget_password"
              alt="忘记密码"
            >
              忘记密码
              <i className="iconfont icon-tip" />
            </a>
            <a
              className="signin-form__signup"
              href="javascript:;"
              alt="创建用户"
              onClick={this.props.switchState}
            >
              &nbsp;创建用户
            </a>
          </span>
        </p>
      </form>
    );
  }
}

SigninForm.propTypes = {
  switchState: PropTypes.func.isRequired,
  isFormValidate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  changeValue: PropTypes.func.isRequired,
};

export default FormWrapper({
  login_name: buildRules("login_name", "手机号/邮箱", [mobile_or_email_rule]),
  password: buildRules("password", "密码", [pwd_rule]),
})(SigninForm);
