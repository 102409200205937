import React, { Fragment } from "react";
import PropTypes from "prop-types";

import logoPath from "logo-black.png";
import ReduxWrapper from "../utils/ReduxWrapper";
import SigninForm from "./SigninForm";
import SignupForm from "./SignupForm";

class SignModal extends React.Component {
  state = {
    isSignin: true,
  };

  wxLogin = () => {
    let url = "";
    const mdetect = require("mdetect");

    if (mdetect.isWechat()) {
      url = `${window.location.origin}/auth/wechatservice`;
    } else {
      url = `https://open.weixin.qq.com/connect/qrconnect?appid=wx8c9a93acb12e2b2d&scope=snsapi_login&redirect_uri=${window.location.origin}/auth/wechat/callback&state=&login_type=jssdk&self_redirect=default`;
    }
    window.location.href = url;
  };

  switchState() {
    const { isSignin } = this.state;
    this.setState({
      isSignin: !isSignin,
    });
  }

  render() {
    const { closeModal } = this.props.actions;
    const { isSignin } = this.state;

    return (
      <div className="signin-modal js-signin-modal">
        <div className="signin-modal__left">
          <img className="signin-modal__logo" src={logoPath} alt="logo" />
          {isSignin ? (
            <Fragment>
              <h2>欢迎回来</h2>
              <SigninForm
                switchState={() => {
                  this.switchState();
                }}
              />
            </Fragment>
          ) : (
            <Fragment>
              <h2>欢迎加入</h2>
              <SignupForm
                switchState={() => {
                  this.switchState();
                }}
              />
            </Fragment>
          )}
          <div className="signin-form__btns">
            <a
              className="signin-form__btn wechat"
              ref="wechat"
              href="javascript:;"
              title="微信登录"
              onClick={this.wxLogin}
            >
              <i className="iconfont icon-wechat" />
            </a>
            <a
              className="signin-form__btn github"
              href="/auth/github"
              title="GitHub登录"
            >
              <i className="iconfont icon-github1" />
            </a>
            <a
              className="signin-form__btn weibo"
              href="/auth/weibo"
              title="微博登录"
            >
              <i className="iconfont icon-weibo" />
            </a>
          </div>
          <div className="signin-form__xy">
            注册即代表我已阅读并同意{" "}
            <a
              href="https://image.jiqizhixin.com/uploads/attachment/file/ca9229c0-1bff-43ef-9e84-1eb7f5939ced/attachment.txt"
              target="_blank"
            >
              《用户协议》
            </a>
          </div>
        </div>
        <div className="signin-modal__right">
          <div className="signin-modal__body" />
        </div>
        <a
          className="signin-modal__close js-switch-sign"
          href="javascript:;"
          onClick={closeModal}
        >
          <i className="iconfont icon-iconguanbi" />
        </a>
      </div>
    );
  }
}

SignModal.propTypes = {
  actions: PropTypes.object.isRequired,
};

export default ReduxWrapper(SignModal);
