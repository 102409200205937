/* eslint-disable no-useless-escape */
import React from "react";
import Salert from "react-s-alert";
import PropTypes from "prop-types";

import {
  email_rule,
  pwd_rule,
  rucaptcha_rule,
  nickname_rule,
  buildRules,
} from "@shared/scripts/utils/validation_rules";
import CustomizeInput from "../shared/CustomizeInput";
import FormWrapper from "../utils/FormWrapper";

class SignupForm extends React.Component {
  updateRucaptcha = e => {
    const $rucaptcha = $(e.target);
    $rucaptcha.attr("src", $rucaptcha.attr("src"));
  };

  submit() {
    if (!this.props.isFormValidate()) return;

    const $form = $(this.refs.form);
    $form.ajaxSubmit({
      url: $form.attr("action"),
      type: "POST",
      dataType: "json",
      success: res => {
        Salert.success("注册成功");
        if (res && res.redirect) {
          window.location.href = res.redirect;
        } else {
          window.location.href = "/";
        }
      },
      error: xhr => {
        Salert.error(xhr.responseText);
        $(this.refs.rucaptcha).click();
      },
    });
  }

  render() {
    const {
      data: { email, password, name, code },
      changeValue,
    } = this.props;

    return (
      <form
        ref="form"
        action="/signup"
        className="signin-form"
        onKeyDown={e => {
          if (e.which === 13) this.submit();
        }}
      >
        <input name="utf8" type="hidden" value="✓" />
        <CustomizeInput
          {...email}
          onChange={value => {
            changeValue(value, "email");
          }}
        />
        <CustomizeInput
          {...name}
          onChange={value => {
            changeValue(value, "name");
          }}
        />
        {/* <CustomizeInput {...mobile} onChange={(value) => { changeValue(value, 'mobile'); }} /> */}
        <div className="u-pic-code">
          <CustomizeInput
            {...code}
            onChange={value => {
              changeValue(value, "code");
            }}
          />
          <img
            ref="rucaptcha"
            src="/rucaptcha/"
            alt="图形验证码"
            onClick={e => this.updateRucaptcha(e)}
          />
        </div>
        <CustomizeInput
          {...password}
          type="password"
          onChange={value => {
            changeValue(value, "password");
          }}
        />
        <a
          className="u-btn--black signin-form__submit"
          href="javascript:;"
          alt="登录"
          onClick={() => {
            this.submit();
          }}
        >
          注册
        </a>
        <p className="signin-form__tip">
          已有账号，立即
          <a
            className="signin-form__signup"
            href="javascript:;"
            alt="登录"
            onClick={this.props.switchState}
          >
            &nbsp;登录
          </a>
        </p>
      </form>
    );
  }
}

SignupForm.propTypes = {
  switchState: PropTypes.func.isRequired,
  isFormValidate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  changeValue: PropTypes.func.isRequired,
};

export default FormWrapper({
  email: buildRules("user[email]", "电子邮箱", [email_rule]),
  password: buildRules("user[password]", "密码", [pwd_rule]),
  name: buildRules("user[name]", "昵称", [nickname_rule]),
  code: buildRules("_rucaptcha", "图形验证码", [rucaptcha_rule]),
})(SignupForm);
